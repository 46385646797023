"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.normalizeHeaderValue = void 0;
function normalizeHeaderValue(value) {
    if (typeof value !== 'string') {
        value = String(value);
    }
    return value;
}
exports.normalizeHeaderValue = normalizeHeaderValue;
