export { i as context } from './index-deps.js';
import { c as commonjsGlobal, p as parse_1, l as lib$2, a as lib$3, j as jsonParse } from './fetch-deps.js';
import { _ as __awaiter, d as devUtils, p as parseBody, g as getPublicUrlFromRequest, N as NetworkError } from './RequestHandler-deps.js';
export { R as RequestHandler, f as cleanUrl, e as compose, c as createResponseComposition, b as defaultContext, a as defaultResponse, m as matchRequestUrl, r as response } from './RequestHandler-deps.js';
import { store } from '@mswjs/cookies';
import { i as isStringEqual, R as RestHandler } from './rest-deps.js';
export { a as RESTMethods, R as RestHandler, r as rest, b as restContext } from './rest-deps.js';
import { t as tryCatch, p as parseGraphQLRequest, G as GraphQLHandler } from './graphql-deps.js';
export { G as GraphQLHandler, g as graphql, a as graphqlContext } from './graphql-deps.js';
import { m as mergeRight } from './errors-deps.js';
import { createInterceptor } from '@mswjs/interceptors';
import { interceptFetch } from '@mswjs/interceptors/lib/interceptors/fetch';
import { interceptXMLHttpRequest } from '@mswjs/interceptors/lib/interceptors/XMLHttpRequest';
import './xml-deps.js';
import '@mswjs/interceptors/lib/utils/getCleanUrl';

var lib$1 = {};

var StrictEventEmitter$1 = {};

var events = {exports: {}};

var R = typeof Reflect === 'object' ? Reflect : null;
var ReflectApply = R && typeof R.apply === 'function'
  ? R.apply
  : function ReflectApply(target, receiver, args) {
    return Function.prototype.apply.call(target, receiver, args);
  };

var ReflectOwnKeys;
if (R && typeof R.ownKeys === 'function') {
  ReflectOwnKeys = R.ownKeys;
} else if (Object.getOwnPropertySymbols) {
  ReflectOwnKeys = function ReflectOwnKeys(target) {
    return Object.getOwnPropertyNames(target)
      .concat(Object.getOwnPropertySymbols(target));
  };
} else {
  ReflectOwnKeys = function ReflectOwnKeys(target) {
    return Object.getOwnPropertyNames(target);
  };
}

function ProcessEmitWarning(warning) {
  if (console && console.warn) console.warn(warning);
}

var NumberIsNaN = Number.isNaN || function NumberIsNaN(value) {
  return value !== value;
};

function EventEmitter() {
  EventEmitter.init.call(this);
}
events.exports = EventEmitter;
events.exports.once = once;

// Backwards-compat with node 0.10.x
EventEmitter.EventEmitter = EventEmitter;

EventEmitter.prototype._events = undefined;
EventEmitter.prototype._eventsCount = 0;
EventEmitter.prototype._maxListeners = undefined;

// By default EventEmitters will print a warning if more than 10 listeners are
// added to it. This is a useful default which helps finding memory leaks.
var defaultMaxListeners = 10;

function checkListener(listener) {
  if (typeof listener !== 'function') {
    throw new TypeError('The "listener" argument must be of type Function. Received type ' + typeof listener);
  }
}

Object.defineProperty(EventEmitter, 'defaultMaxListeners', {
  enumerable: true,
  get: function() {
    return defaultMaxListeners;
  },
  set: function(arg) {
    if (typeof arg !== 'number' || arg < 0 || NumberIsNaN(arg)) {
      throw new RangeError('The value of "defaultMaxListeners" is out of range. It must be a non-negative number. Received ' + arg + '.');
    }
    defaultMaxListeners = arg;
  }
});

EventEmitter.init = function() {

  if (this._events === undefined ||
      this._events === Object.getPrototypeOf(this)._events) {
    this._events = Object.create(null);
    this._eventsCount = 0;
  }

  this._maxListeners = this._maxListeners || undefined;
};

// Obviously not all Emitters should be limited to 10. This function allows
// that to be increased. Set to zero for unlimited.
EventEmitter.prototype.setMaxListeners = function setMaxListeners(n) {
  if (typeof n !== 'number' || n < 0 || NumberIsNaN(n)) {
    throw new RangeError('The value of "n" is out of range. It must be a non-negative number. Received ' + n + '.');
  }
  this._maxListeners = n;
  return this;
};

function _getMaxListeners(that) {
  if (that._maxListeners === undefined)
    return EventEmitter.defaultMaxListeners;
  return that._maxListeners;
}

EventEmitter.prototype.getMaxListeners = function getMaxListeners() {
  return _getMaxListeners(this);
};

EventEmitter.prototype.emit = function emit(type) {
  var args = [];
  for (var i = 1; i < arguments.length; i++) args.push(arguments[i]);
  var doError = (type === 'error');

  var events = this._events;
  if (events !== undefined)
    doError = (doError && events.error === undefined);
  else if (!doError)
    return false;

  // If there is no 'error' event listener then throw.
  if (doError) {
    var er;
    if (args.length > 0)
      er = args[0];
    if (er instanceof Error) {
      // Note: The comments on the `throw` lines are intentional, they show
      // up in Node's output if this results in an unhandled exception.
      throw er; // Unhandled 'error' event
    }
    // At least give some kind of context to the user
    var err = new Error('Unhandled error.' + (er ? ' (' + er.message + ')' : ''));
    err.context = er;
    throw err; // Unhandled 'error' event
  }

  var handler = events[type];

  if (handler === undefined)
    return false;

  if (typeof handler === 'function') {
    ReflectApply(handler, this, args);
  } else {
    var len = handler.length;
    var listeners = arrayClone(handler, len);
    for (var i = 0; i < len; ++i)
      ReflectApply(listeners[i], this, args);
  }

  return true;
};

function _addListener(target, type, listener, prepend) {
  var m;
  var events;
  var existing;

  checkListener(listener);

  events = target._events;
  if (events === undefined) {
    events = target._events = Object.create(null);
    target._eventsCount = 0;
  } else {
    // To avoid recursion in the case that type === "newListener"! Before
    // adding it to the listeners, first emit "newListener".
    if (events.newListener !== undefined) {
      target.emit('newListener', type,
                  listener.listener ? listener.listener : listener);

      // Re-assign `events` because a newListener handler could have caused the
      // this._events to be assigned to a new object
      events = target._events;
    }
    existing = events[type];
  }

  if (existing === undefined) {
    // Optimize the case of one listener. Don't need the extra array object.
    existing = events[type] = listener;
    ++target._eventsCount;
  } else {
    if (typeof existing === 'function') {
      // Adding the second element, need to change to array.
      existing = events[type] =
        prepend ? [listener, existing] : [existing, listener];
      // If we've already got an array, just append.
    } else if (prepend) {
      existing.unshift(listener);
    } else {
      existing.push(listener);
    }

    // Check for listener leak
    m = _getMaxListeners(target);
    if (m > 0 && existing.length > m && !existing.warned) {
      existing.warned = true;
      // No error code for this since it is a Warning
      // eslint-disable-next-line no-restricted-syntax
      var w = new Error('Possible EventEmitter memory leak detected. ' +
                          existing.length + ' ' + String(type) + ' listeners ' +
                          'added. Use emitter.setMaxListeners() to ' +
                          'increase limit');
      w.name = 'MaxListenersExceededWarning';
      w.emitter = target;
      w.type = type;
      w.count = existing.length;
      ProcessEmitWarning(w);
    }
  }

  return target;
}

EventEmitter.prototype.addListener = function addListener(type, listener) {
  return _addListener(this, type, listener, false);
};

EventEmitter.prototype.on = EventEmitter.prototype.addListener;

EventEmitter.prototype.prependListener =
    function prependListener(type, listener) {
      return _addListener(this, type, listener, true);
    };

function onceWrapper() {
  if (!this.fired) {
    this.target.removeListener(this.type, this.wrapFn);
    this.fired = true;
    if (arguments.length === 0)
      return this.listener.call(this.target);
    return this.listener.apply(this.target, arguments);
  }
}

function _onceWrap(target, type, listener) {
  var state = { fired: false, wrapFn: undefined, target: target, type: type, listener: listener };
  var wrapped = onceWrapper.bind(state);
  wrapped.listener = listener;
  state.wrapFn = wrapped;
  return wrapped;
}

EventEmitter.prototype.once = function once(type, listener) {
  checkListener(listener);
  this.on(type, _onceWrap(this, type, listener));
  return this;
};

EventEmitter.prototype.prependOnceListener =
    function prependOnceListener(type, listener) {
      checkListener(listener);
      this.prependListener(type, _onceWrap(this, type, listener));
      return this;
    };

// Emits a 'removeListener' event if and only if the listener was removed.
EventEmitter.prototype.removeListener =
    function removeListener(type, listener) {
      var list, events, position, i, originalListener;

      checkListener(listener);

      events = this._events;
      if (events === undefined)
        return this;

      list = events[type];
      if (list === undefined)
        return this;

      if (list === listener || list.listener === listener) {
        if (--this._eventsCount === 0)
          this._events = Object.create(null);
        else {
          delete events[type];
          if (events.removeListener)
            this.emit('removeListener', type, list.listener || listener);
        }
      } else if (typeof list !== 'function') {
        position = -1;

        for (i = list.length - 1; i >= 0; i--) {
          if (list[i] === listener || list[i].listener === listener) {
            originalListener = list[i].listener;
            position = i;
            break;
          }
        }

        if (position < 0)
          return this;

        if (position === 0)
          list.shift();
        else {
          spliceOne(list, position);
        }

        if (list.length === 1)
          events[type] = list[0];

        if (events.removeListener !== undefined)
          this.emit('removeListener', type, originalListener || listener);
      }

      return this;
    };

EventEmitter.prototype.off = EventEmitter.prototype.removeListener;

EventEmitter.prototype.removeAllListeners =
    function removeAllListeners(type) {
      var listeners, events, i;

      events = this._events;
      if (events === undefined)
        return this;

      // not listening for removeListener, no need to emit
      if (events.removeListener === undefined) {
        if (arguments.length === 0) {
          this._events = Object.create(null);
          this._eventsCount = 0;
        } else if (events[type] !== undefined) {
          if (--this._eventsCount === 0)
            this._events = Object.create(null);
          else
            delete events[type];
        }
        return this;
      }

      // emit removeListener for all listeners on all events
      if (arguments.length === 0) {
        var keys = Object.keys(events);
        var key;
        for (i = 0; i < keys.length; ++i) {
          key = keys[i];
          if (key === 'removeListener') continue;
          this.removeAllListeners(key);
        }
        this.removeAllListeners('removeListener');
        this._events = Object.create(null);
        this._eventsCount = 0;
        return this;
      }

      listeners = events[type];

      if (typeof listeners === 'function') {
        this.removeListener(type, listeners);
      } else if (listeners !== undefined) {
        // LIFO order
        for (i = listeners.length - 1; i >= 0; i--) {
          this.removeListener(type, listeners[i]);
        }
      }

      return this;
    };

function _listeners(target, type, unwrap) {
  var events = target._events;

  if (events === undefined)
    return [];

  var evlistener = events[type];
  if (evlistener === undefined)
    return [];

  if (typeof evlistener === 'function')
    return unwrap ? [evlistener.listener || evlistener] : [evlistener];

  return unwrap ?
    unwrapListeners(evlistener) : arrayClone(evlistener, evlistener.length);
}

EventEmitter.prototype.listeners = function listeners(type) {
  return _listeners(this, type, true);
};

EventEmitter.prototype.rawListeners = function rawListeners(type) {
  return _listeners(this, type, false);
};

EventEmitter.listenerCount = function(emitter, type) {
  if (typeof emitter.listenerCount === 'function') {
    return emitter.listenerCount(type);
  } else {
    return listenerCount.call(emitter, type);
  }
};

EventEmitter.prototype.listenerCount = listenerCount;
function listenerCount(type) {
  var events = this._events;

  if (events !== undefined) {
    var evlistener = events[type];

    if (typeof evlistener === 'function') {
      return 1;
    } else if (evlistener !== undefined) {
      return evlistener.length;
    }
  }

  return 0;
}

EventEmitter.prototype.eventNames = function eventNames() {
  return this._eventsCount > 0 ? ReflectOwnKeys(this._events) : [];
};

function arrayClone(arr, n) {
  var copy = new Array(n);
  for (var i = 0; i < n; ++i)
    copy[i] = arr[i];
  return copy;
}

function spliceOne(list, index) {
  for (; index + 1 < list.length; index++)
    list[index] = list[index + 1];
  list.pop();
}

function unwrapListeners(arr) {
  var ret = new Array(arr.length);
  for (var i = 0; i < ret.length; ++i) {
    ret[i] = arr[i].listener || arr[i];
  }
  return ret;
}

function once(emitter, name) {
  return new Promise(function (resolve, reject) {
    function errorListener(err) {
      emitter.removeListener(name, resolver);
      reject(err);
    }

    function resolver() {
      if (typeof emitter.removeListener === 'function') {
        emitter.removeListener('error', errorListener);
      }
      resolve([].slice.call(arguments));
    }
    eventTargetAgnosticAddListener(emitter, name, resolver, { once: true });
    if (name !== 'error') {
      addErrorHandlerIfEventEmitter(emitter, errorListener, { once: true });
    }
  });
}

function addErrorHandlerIfEventEmitter(emitter, handler, flags) {
  if (typeof emitter.on === 'function') {
    eventTargetAgnosticAddListener(emitter, 'error', handler, flags);
  }
}

function eventTargetAgnosticAddListener(emitter, name, listener, flags) {
  if (typeof emitter.on === 'function') {
    if (flags.once) {
      emitter.once(name, listener);
    } else {
      emitter.on(name, listener);
    }
  } else if (typeof emitter.addEventListener === 'function') {
    // EventTarget does not have `error` event semantics like Node
    // EventEmitters, we do not listen for `error` events here.
    emitter.addEventListener(name, function wrapListener(arg) {
      // IE does not have builtin `{ once: true }` support so we
      // have to do it manually.
      if (flags.once) {
        emitter.removeEventListener(name, wrapListener);
      }
      listener(arg);
    });
  } else {
    throw new TypeError('The "emitter" argument must be of type EventEmitter. Received type ' + typeof emitter);
  }
}

var __extends = (commonjsGlobal && commonjsGlobal.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __spreadArrays = (commonjsGlobal && commonjsGlobal.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
StrictEventEmitter$1.__esModule = true;
StrictEventEmitter$1.StrictEventEmitter = void 0;
var events_1 = events.exports;
var StrictEventEmitter = /** @class */ (function (_super) {
    __extends(StrictEventEmitter, _super);
    function StrictEventEmitter() {
        return _super.call(this) || this;
    }
    StrictEventEmitter.prototype.on = function (event, listener) {
        return _super.prototype.on.call(this, event.toString(), listener);
    };
    StrictEventEmitter.prototype.once = function (event, listener) {
        return _super.prototype.on.call(this, event.toString(), listener);
    };
    StrictEventEmitter.prototype.off = function (event, listener) {
        return _super.prototype.off.call(this, event.toString(), listener);
    };
    StrictEventEmitter.prototype.emit = function (event) {
        var data = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            data[_i - 1] = arguments[_i];
        }
        return _super.prototype.emit.apply(this, __spreadArrays([event.toString()], data));
    };
    StrictEventEmitter.prototype.addListener = function (event, listener) {
        return _super.prototype.addListener.call(this, event.toString(), listener);
    };
    StrictEventEmitter.prototype.removeListener = function (event, listener) {
        return _super.prototype.removeListener.call(this, event.toString(), listener);
    };
    return StrictEventEmitter;
}(events_1.EventEmitter));
StrictEventEmitter$1.StrictEventEmitter = StrictEventEmitter;

(function (exports) {
var __createBinding = (commonjsGlobal && commonjsGlobal.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
exports.__esModule = true;
exports.StrictEventEmitter = void 0;
var StrictEventEmitter_1 = StrictEventEmitter$1;
__createBinding(exports, StrictEventEmitter_1, "StrictEventEmitter");
}(lib$1));

var lib = {};

var until$1 = {};

Object.defineProperty(until$1, "__esModule", { value: true });
/**
 * Gracefully handles a given Promise factory.
 * @example
 * cosnt [error, data] = await until(() => asyncAction())
 */
until$1.until = async (promise) => {
    try {
        const data = await promise().catch((error) => {
            throw error;
        });
        return [null, data];
    }
    catch (error) {
        return [error, null];
    }
};

Object.defineProperty(lib, "__esModule", { value: true });
var until_1 = until$1;
var until = lib.until = until_1.until;

/**
 * Attempts to resolve a Service Worker instance from a given registration,
 * regardless of its state (active, installing, waiting).
 */
const getWorkerByRegistration = (registration, absoluteWorkerUrl, findWorker) => {
    const allStates = [
        registration.active,
        registration.installing,
        registration.waiting,
    ];
    const existingStates = allStates.filter(Boolean);
    const mockWorker = existingStates.find((worker) => {
        return findWorker(worker.scriptURL, absoluteWorkerUrl);
    });
    return mockWorker || null;
};

/**
 * Returns an absolute Service Worker URL based on the given
 * relative URL (known during the registration).
 */
function getAbsoluteWorkerUrl(relativeUrl) {
    return new URL(relativeUrl, location.origin).href;
}

/**
 * Returns an active Service Worker instance.
 * When not found, registers a new Service Worker.
 */
const getWorkerInstance = (url, options = {}, findWorker) => __awaiter(void 0, void 0, void 0, function* () {
    // Resolve the absolute Service Worker URL.
    const absoluteWorkerUrl = getAbsoluteWorkerUrl(url);
    const mockRegistrations = yield navigator.serviceWorker
        .getRegistrations()
        .then((registrations) => registrations.filter((registration) => getWorkerByRegistration(registration, absoluteWorkerUrl, findWorker)));
    if (!navigator.serviceWorker.controller && mockRegistrations.length > 0) {
        // Reload the page when it has associated workers, but no active controller.
        // The absence of a controller can mean either:
        // - page has no Service Worker associated with it
        // - page has been hard-reloaded and its workers won't be used until the next reload.
        // Since we've checked that there are registrations associated with this page,
        // at this point we are sure it's hard reload that falls into this clause.
        location.reload();
    }
    const [existingRegistration] = mockRegistrations;
    if (existingRegistration) {
        // When the Service Worker is registered, update it and return the reference.
        return existingRegistration.update().then(() => {
            return [
                getWorkerByRegistration(existingRegistration, absoluteWorkerUrl, findWorker),
                existingRegistration,
            ];
        });
    }
    // When the Service Worker wasn't found, register it anew and return the reference.
    const [error, instance] = yield until(() => __awaiter(void 0, void 0, void 0, function* () {
        const registration = yield navigator.serviceWorker.register(url, options);
        return [
            // Compare existing worker registration by its worker URL,
            // to prevent irrelevant workers to resolve here (such as Codesandbox worker).
            getWorkerByRegistration(registration, absoluteWorkerUrl, findWorker),
            registration,
        ];
    }));
    // Handle Service Worker registration errors.
    if (error) {
        const isWorkerMissing = error.message.includes('(404)');
        // Produce a custom error message when given a non-existing Service Worker url.
        // Suggest developers to check their setup.
        if (isWorkerMissing) {
            const scopeUrl = new URL((options === null || options === void 0 ? void 0 : options.scope) || '/', location.href);
            throw new Error(devUtils.formatMessage(`\
Failed to register a Service Worker for scope ('${scopeUrl.href}') with script ('${absoluteWorkerUrl}'): Service Worker script does not exist at the given path.

Did you forget to run "npx msw init <PUBLIC_DIR>"?

Learn more about creating the Service Worker script: https://mswjs.io/docs/cli/init`));
        }
        // Fallback error message for any other registration errors.
        throw new Error(devUtils.formatMessage('Failed to register the Service Worker:\n\n%s', error.message));
    }
    return instance;
});

/**
 * Prints a worker activation message in the browser's console.
 */
function printStartMessage(args = {}) {
    if (args.quiet) {
        return;
    }
    const message = args.message || 'Mocking enabled.';
    console.groupCollapsed(`%c${devUtils.formatMessage(message)}`, 'color:orangered;font-weight:bold;');
    console.log('%cDocumentation: %chttps://mswjs.io/docs', 'font-weight:bold', 'font-weight:normal');
    console.log('Found an issue? https://github.com/mswjs/msw/issues');
    console.groupEnd();
}

/**
 * Signals the worker to enable the interception of requests.
 */
function enableMocking(context, options) {
    return __awaiter(this, void 0, void 0, function* () {
        context.workerChannel.send('MOCK_ACTIVATE');
        return context.events.once('MOCKING_ENABLED').then(() => {
            printStartMessage({ quiet: options.quiet });
        });
    });
}

/**
 * Creates a communication channel between the client
 * and the Service Worker associated with the given event.
 */
const createBroadcastChannel = (event) => {
    const port = event.ports[0];
    return {
        /**
         * Sends a text message to the connected Service Worker.
         */
        send(message) {
            if (port) {
                port.postMessage(message);
            }
        },
    };
};

function getAllCookies() {
    return parse_1(document.cookie);
}
/**
 * Returns relevant document cookies based on the request `credentials` option.
 */
function getRequestCookies(request) {
    /**
     * @note No cookies persist on the document in Node.js: no document.
     */
    if (typeof document === 'undefined' || typeof location === 'undefined') {
        return {};
    }
    switch (request.credentials) {
        case 'same-origin': {
            // Return document cookies only when requested a resource
            // from the same origin as the current document.
            return location.origin === request.url.origin ? getAllCookies() : {};
        }
        case 'include': {
            // Return all document cookies.
            return getAllCookies();
        }
        default: {
            return {};
        }
    }
}

/**
 * Sets relevant cookies on the request.
 * Request cookies are taken from the following sources:
 * - Immediate (own) request cookies (those in the "Cookie" request header);
 * - From the `document.cookie` based on the request's `credentials` value;
 * - From the internal cookie store that persists/hydrates cookies in Node.js
 */
function setRequestCookies(request) {
    var _a;
    // Set mocked request cookies from the `cookie` header of the original request.
    // No need to take `credentials` into account, because in Node.js requests are intercepted
    // _after_ they happen. Request issuer should have already taken care of sending relevant cookies.
    // Unlike browser, where interception is on the worker level, _before_ the request happens.
    const requestCookiesString = request.headers.get('cookie');
    store.hydrate();
    const cookiesFromStore = Array.from((_a = store.get(Object.assign(Object.assign({}, request), { url: request.url.toString() }))) === null || _a === void 0 ? void 0 : _a.entries()).reduce((cookies, [name, { value }]) => {
        return Object.assign(cookies, { [name.trim()]: value });
    }, {});
    const cookiesFromDocument = getRequestCookies(request);
    const forwardedCookies = Object.assign(Object.assign({}, cookiesFromDocument), cookiesFromStore);
    // Ensure the persisted (document) cookies are propagated to the request.
    // Propagated the cookies persisted in the Cookuie Store to the request headers.
    // This forwards relevant request cookies based on the request's credentials.
    for (const [name, value] of Object.entries(forwardedCookies)) {
        request.headers.append('cookie', `${name}=${value}`);
    }
    const ownCookies = requestCookiesString
        ? parse_1(requestCookiesString)
        : {};
    request.cookies = Object.assign(Object.assign(Object.assign({}, request.cookies), forwardedCookies), ownCookies);
}

/**
 * Ensures that an empty GET request body is always represented as `undefined`.
 */
function pruneGetRequestBody(request) {
    if (request.method &&
        isStringEqual(request.method, 'GET') &&
        request.body === '') {
        return undefined;
    }
    return request.body;
}

/**
 * Converts a given request received from the Service Worker
 * into a `MockedRequest` instance.
 */
function parseWorkerRequest(rawRequest) {
    const request = {
        id: rawRequest.id,
        cache: rawRequest.cache,
        credentials: rawRequest.credentials,
        method: rawRequest.method,
        url: new URL(rawRequest.url),
        referrer: rawRequest.referrer,
        referrerPolicy: rawRequest.referrerPolicy,
        redirect: rawRequest.redirect,
        mode: rawRequest.mode,
        params: {},
        cookies: {},
        integrity: rawRequest.integrity,
        keepalive: rawRequest.keepalive,
        destination: rawRequest.destination,
        body: pruneGetRequestBody(rawRequest),
        bodyUsed: rawRequest.bodyUsed,
        headers: new lib$2.Headers(rawRequest.headers),
    };
    // Set document cookies on the request.
    setRequestCookies(request);
    // Parse the request's body based on the "Content-Type" header.
    request.body = parseBody(request.body, request.headers);
    return request;
}

/**
 * Returns a mocked response for a given request using following request handlers.
 */
const getResponse = (request, handlers, resolutionContext) => __awaiter(void 0, void 0, void 0, function* () {
    const relevantHandlers = handlers.filter((handler) => {
        return handler.test(request, resolutionContext);
    });
    if (relevantHandlers.length === 0) {
        return {
            handler: undefined,
            response: undefined,
        };
    }
    const result = yield relevantHandlers.reduce((executionResult, handler) => __awaiter(void 0, void 0, void 0, function* () {
        const previousResults = yield executionResult;
        if (!!(previousResults === null || previousResults === void 0 ? void 0 : previousResults.response)) {
            return executionResult;
        }
        const result = yield handler.run(request, resolutionContext);
        if (result === null || result.handler.shouldSkip) {
            return null;
        }
        if (!result.response) {
            return {
                request: result.request,
                handler: result.handler,
                response: undefined,
                parsedResult: result.parsedResult,
            };
        }
        if (result.response.once) {
            handler.markAsSkipped(true);
        }
        return result;
    }), Promise.resolve(null));
    // Although reducing a list of relevant request handlers, it's possible
    // that in the end there will be no handler associted with the request
    // (i.e. if relevant handlers are fall-through).
    if (!result) {
        return {
            handler: undefined,
            response: undefined,
        };
    }
    return {
        handler: result.handler,
        publicRequest: result.request,
        parsedRequest: result.parsedResult,
        response: result.response,
    };
});

var jsLevenshtein = (function()
{
  function _min(d0, d1, d2, bx, ay)
  {
    return d0 < d1 || d2 < d1
        ? d0 > d2
            ? d2 + 1
            : d0 + 1
        : bx === ay
            ? d1
            : d1 + 1;
  }

  return function(a, b)
  {
    if (a === b) {
      return 0;
    }

    if (a.length > b.length) {
      var tmp = a;
      a = b;
      b = tmp;
    }

    var la = a.length;
    var lb = b.length;

    while (la > 0 && (a.charCodeAt(la - 1) === b.charCodeAt(lb - 1))) {
      la--;
      lb--;
    }

    var offset = 0;

    while (offset < la && (a.charCodeAt(offset) === b.charCodeAt(offset))) {
      offset++;
    }

    la -= offset;
    lb -= offset;

    if (la === 0 || lb < 3) {
      return lb;
    }

    var x = 0;
    var y;
    var d0;
    var d1;
    var d2;
    var d3;
    var dd;
    var dy;
    var ay;
    var bx0;
    var bx1;
    var bx2;
    var bx3;

    var vector = [];

    for (y = 0; y < la; y++) {
      vector.push(y + 1);
      vector.push(a.charCodeAt(offset + y));
    }

    var len = vector.length - 1;

    for (; x < lb - 3;) {
      bx0 = b.charCodeAt(offset + (d0 = x));
      bx1 = b.charCodeAt(offset + (d1 = x + 1));
      bx2 = b.charCodeAt(offset + (d2 = x + 2));
      bx3 = b.charCodeAt(offset + (d3 = x + 3));
      dd = (x += 4);
      for (y = 0; y < len; y += 2) {
        dy = vector[y];
        ay = vector[y + 1];
        d0 = _min(dy, d0, d1, bx0, ay);
        d1 = _min(d0, d1, d2, bx1, ay);
        d2 = _min(d1, d2, d3, bx2, ay);
        dd = _min(d2, d3, dd, bx3, ay);
        vector[y] = dd;
        d3 = d2;
        d2 = d1;
        d1 = d0;
        d0 = dy;
      }
    }

    for (; x < lb;) {
      bx0 = b.charCodeAt(offset + (d0 = x));
      dd = ++x;
      for (y = 0; y < len; y += 2) {
        dy = vector[y];
        vector[y] = dd = _min(dy, d0, dd, bx0, vector[y + 1]);
        d0 = dy;
      }
    }

    return dd;
  };
})();

const MAX_MATCH_SCORE = 3;
const MAX_SUGGESTION_COUNT = 4;
const TYPE_MATCH_DELTA = 0.5;
function groupHandlersByType(handlers) {
    return handlers.reduce((groups, handler) => {
        if (handler instanceof RestHandler) {
            groups.rest.push(handler);
        }
        if (handler instanceof GraphQLHandler) {
            groups.graphql.push(handler);
        }
        return groups;
    }, {
        rest: [],
        graphql: [],
    });
}
function getRestHandlerScore() {
    return (request, handler) => {
        const { path, method } = handler.info;
        if (path instanceof RegExp || method instanceof RegExp) {
            return Infinity;
        }
        const hasSameMethod = isStringEqual(request.method, method);
        // Always treat a handler with the same method as a more similar one.
        const methodScoreDelta = hasSameMethod ? TYPE_MATCH_DELTA : 0;
        const requestPublicUrl = getPublicUrlFromRequest(request);
        const score = jsLevenshtein(requestPublicUrl, path);
        return score - methodScoreDelta;
    };
}
function getGraphQLHandlerScore(parsedQuery) {
    return (_, handler) => {
        if (typeof parsedQuery.operationName === 'undefined') {
            return Infinity;
        }
        const { operationType, operationName } = handler.info;
        if (typeof operationName !== 'string') {
            return Infinity;
        }
        const hasSameOperationType = parsedQuery.operationType === operationType;
        // Always treat a handler with the same operation type as a more similar one.
        const operationTypeScoreDelta = hasSameOperationType ? TYPE_MATCH_DELTA : 0;
        const score = jsLevenshtein(parsedQuery.operationName, operationName);
        return score - operationTypeScoreDelta;
    };
}
function getSuggestedHandler(request, handlers, getScore) {
    const suggestedHandlers = handlers
        .reduce((suggestions, handler) => {
        const score = getScore(request, handler);
        return suggestions.concat([[score, handler]]);
    }, [])
        .sort(([leftScore], [rightScore]) => leftScore - rightScore)
        .filter(([score]) => score <= MAX_MATCH_SCORE)
        .slice(0, MAX_SUGGESTION_COUNT)
        .map(([, handler]) => handler);
    return suggestedHandlers;
}
function getSuggestedHandlersMessage(handlers) {
    if (handlers.length > 1) {
        return `\
Did you mean to request one of the following resources instead?

${handlers.map((handler) => `  • ${handler.info.header}`).join('\n')}`;
    }
    return `Did you mean to request "${handlers[0].info.header}" instead?`;
}
function onUnhandledRequest(request, handlers, strategy = 'warn') {
    const parsedGraphQLQuery = tryCatch(() => parseGraphQLRequest(request));
    function generateHandlerSuggestion() {
        /**
         * @note Ignore exceptions during GraphQL request parsing because at this point
         * we cannot assume the unhandled request is a valid GraphQL request.
         * If the GraphQL parsing fails, just don't treat it as a GraphQL request.
         */
        const handlerGroups = groupHandlersByType(handlers);
        const relevantHandlers = parsedGraphQLQuery
            ? handlerGroups.graphql
            : handlerGroups.rest;
        const suggestedHandlers = getSuggestedHandler(request, relevantHandlers, parsedGraphQLQuery
            ? getGraphQLHandlerScore(parsedGraphQLQuery)
            : getRestHandlerScore());
        return suggestedHandlers.length > 0
            ? getSuggestedHandlersMessage(suggestedHandlers)
            : '';
    }
    function generateUnhandledRequestMessage() {
        const publicUrl = getPublicUrlFromRequest(request);
        const requestHeader = parsedGraphQLQuery
            ? `${parsedGraphQLQuery.operationType} ${parsedGraphQLQuery.operationName} (${request.method} ${publicUrl})`
            : `${request.method} ${publicUrl}`;
        const handlerSuggestion = generateHandlerSuggestion();
        const messageTemplate = [
            `captured a request without a matching request handler:`,
            `  \u2022 ${requestHeader}`,
            handlerSuggestion,
            `\
If you still wish to intercept this unhandled request, please create a request handler for it.
Read more: https://mswjs.io/docs/getting-started/mocks\
`,
        ].filter(Boolean);
        return messageTemplate.join('\n\n');
    }
    function applyStrategy(strategy) {
        // Generate handler suggestions only when applying the strategy.
        // This saves bandwidth for scenarios when developers opt-out
        // from the default unhandled request handling strategy.
        const message = generateUnhandledRequestMessage();
        switch (strategy) {
            case 'error': {
                // Print a developer-friendly error.
                devUtils.error('Error: %s', message);
                // Throw an exception to halt request processing and not perform the original request.
                throw new Error(devUtils.formatMessage('Cannot bypass a request when using the "error" strategy for the "onUnhandledRequest" option.'));
            }
            case 'warn': {
                devUtils.warn('Warning: %s', message);
                break;
            }
            case 'bypass':
                break;
            default:
                throw new Error(devUtils.formatMessage('Failed to react to an unhandled request: unknown strategy "%s". Please provide one of the supported strategies ("bypass", "warn", "error") or a custom callback function as the value of the "onUnhandledRequest" option.', strategy));
        }
    }
    if (typeof strategy === 'function') {
        strategy(request, {
            warning: applyStrategy.bind(null, 'warn'),
            error: applyStrategy.bind(null, 'error'),
        });
        return;
    }
    applyStrategy(strategy);
}

function readResponseCookies(request, response) {
    store.add(Object.assign(Object.assign({}, request), { url: request.url.toString() }), response);
    store.persist();
}

function handleRequest(request, handlers, options, emitter, handleRequestOptions) {
    var _a, _b, _c;
    return __awaiter(this, void 0, void 0, function* () {
        emitter.emit('request:start', request);
        // Perform bypassed requests (i.e. issued via "ctx.fetch") as-is.
        if (request.headers.get('x-msw-bypass') === 'true') {
            emitter.emit('request:end', request);
            (_a = handleRequestOptions === null || handleRequestOptions === void 0 ? void 0 : handleRequestOptions.onBypassResponse) === null || _a === void 0 ? void 0 : _a.call(handleRequestOptions, request);
            return;
        }
        // Resolve a mocked response from the list of request handlers.
        const lookupResult = yield getResponse(request, handlers, handleRequestOptions === null || handleRequestOptions === void 0 ? void 0 : handleRequestOptions.resolutionContext);
        const { handler, response } = lookupResult;
        // When there's no handler for the request, consider it unhandled.
        // Allow the developer to react to such cases.
        if (!handler) {
            onUnhandledRequest(request, handlers, options.onUnhandledRequest);
            emitter.emit('request:unhandled', request);
            emitter.emit('request:end', request);
            (_b = handleRequestOptions === null || handleRequestOptions === void 0 ? void 0 : handleRequestOptions.onBypassResponse) === null || _b === void 0 ? void 0 : _b.call(handleRequestOptions, request);
            return;
        }
        // When the handled request returned no mocked response, warn the developer,
        // as it may be an oversight on their part. Perform the request as-is.
        if (!response) {
            devUtils.warn(`\
Expected response resolver to return a mocked response Object, but got %s. The original response is going to be used instead.\
\n
  \u2022 %s
    %s\
`, response, handler.info.header, handler.info.callFrame);
            emitter.emit('request:end', request);
            (_c = handleRequestOptions === null || handleRequestOptions === void 0 ? void 0 : handleRequestOptions.onBypassResponse) === null || _c === void 0 ? void 0 : _c.call(handleRequestOptions, request);
            return;
        }
        // Store all the received response cookies in the virtual cookie store.
        readResponseCookies(request, response);
        emitter.emit('request:match', request);
        return new Promise((resolve) => {
            var _a, _b, _c;
            const requiredLookupResult = lookupResult;
            const transformedResponse = ((_a = handleRequestOptions === null || handleRequestOptions === void 0 ? void 0 : handleRequestOptions.transformResponse) === null || _a === void 0 ? void 0 : _a.call(handleRequestOptions, response)) ||
                response;
            (_b = handleRequestOptions === null || handleRequestOptions === void 0 ? void 0 : handleRequestOptions.onMockedResponse) === null || _b === void 0 ? void 0 : _b.call(handleRequestOptions, transformedResponse, requiredLookupResult);
            setTimeout(() => {
                var _a;
                (_a = handleRequestOptions === null || handleRequestOptions === void 0 ? void 0 : handleRequestOptions.onMockedResponseSent) === null || _a === void 0 ? void 0 : _a.call(handleRequestOptions, transformedResponse, requiredLookupResult);
                emitter.emit('request:end', request);
                resolve(transformedResponse);
            }, (_c = response.delay) !== null && _c !== void 0 ? _c : 0);
        });
    });
}

const createRequestListener = (context, options) => {
    return (event, message) => __awaiter(void 0, void 0, void 0, function* () {
        const channel = createBroadcastChannel(event);
        try {
            const request = parseWorkerRequest(message.payload);
            yield handleRequest(request, context.requestHandlers, options, context.emitter, {
                transformResponse(response) {
                    return Object.assign(Object.assign({}, response), { headers: response.headers.all() });
                },
                onBypassResponse() {
                    return channel.send({
                        type: 'MOCK_NOT_FOUND',
                    });
                },
                onMockedResponse(response) {
                    channel.send({
                        type: 'MOCK_SUCCESS',
                        payload: response,
                    });
                },
                onMockedResponseSent(response, { handler, publicRequest, parsedRequest }) {
                    if (!options.quiet) {
                        handler.log(publicRequest, response, handler, parsedRequest);
                    }
                },
            });
        }
        catch (error) {
            if (error instanceof NetworkError) {
                // Treat emulated network error differently,
                // as it is an intended exception in a request handler.
                return channel.send({
                    type: 'NETWORK_ERROR',
                    payload: {
                        name: error.name,
                        message: error.message,
                    },
                });
            }
            // Treat all the other exceptions in a request handler
            // as unintended, alerting that there is a problem needs fixing.
            channel.send({
                type: 'INTERNAL_ERROR',
                payload: {
                    status: 500,
                    body: JSON.stringify({
                        errorType: error.constructor.name,
                        message: error.message,
                        location: error.stack,
                    }),
                },
            });
        }
    });
};

function requestIntegrityCheck(context, serviceWorker) {
    return __awaiter(this, void 0, void 0, function* () {
        // Signal Service Worker to report back its integrity
        context.workerChannel.send('INTEGRITY_CHECK_REQUEST');
        const { payload: actualChecksum } = yield context.events.once('INTEGRITY_CHECK_RESPONSE');
        // Compare the response from the Service Worker and the
        // global variable set by Rollup during the build.
        if (actualChecksum !== "02f4ad4a2797f85668baf196e553d929") {
            throw new Error(`Currently active Service Worker (${actualChecksum}) is behind the latest published one (${"02f4ad4a2797f85668baf196e553d929"}).`);
        }
        return serviceWorker;
    });
}

/**
 * Intercepts and defers any requests on the page
 * until the Service Worker instance is ready.
 * Must only be used in a browser.
 */
function deferNetworkRequestsUntil(predicatePromise) {
    // Defer any `XMLHttpRequest` requests until the Service Worker is ready.
    const originalXhrSend = window.XMLHttpRequest.prototype.send;
    window.XMLHttpRequest.prototype.send = function (...args) {
        // Keep this function synchronous to comply with `XMLHttpRequest.prototype.send`,
        // because that method is always synchronous.
        until(() => predicatePromise).then(() => {
            window.XMLHttpRequest.prototype.send = originalXhrSend;
            this.send(...args);
        });
    };
    // Defer any `fetch` requests until the Service Worker is ready.
    const originalFetch = window.fetch;
    window.fetch = (...args) => __awaiter(this, void 0, void 0, function* () {
        yield until(() => predicatePromise);
        window.fetch = originalFetch;
        return window.fetch(...args);
    });
}

function createResponseListener(context) {
    return (_, message) => {
        var _a;
        const { payload: responseJson } = message;
        /**
         * CORS requests with `mode: "no-cors"` result in "opaque" responses.
         * That kind of responses cannot be manipulated in JavaScript due
         * to the security considerations.
         * @see https://fetch.spec.whatwg.org/#concept-filtered-response-opaque
         * @see https://github.com/mswjs/msw/issues/529
         */
        if ((_a = responseJson.type) === null || _a === void 0 ? void 0 : _a.includes('opaque')) {
            return;
        }
        const response = new Response(responseJson.body || null, responseJson);
        const isMockedResponse = response.headers.get('x-powered-by') === 'msw';
        if (isMockedResponse) {
            context.emitter.emit('response:mocked', response, responseJson.requestId);
        }
        else {
            context.emitter.emit('response:bypass', response, responseJson.requestId);
        }
    };
}

function validateWorkerScope(registration, options) {
    if (!(options === null || options === void 0 ? void 0 : options.quiet) && !location.href.startsWith(registration.scope)) {
        devUtils.warn(`\
Cannot intercept requests on this page because it's outside of the worker's scope ("${registration.scope}"). If you wish to mock API requests on this page, you must resolve this scope issue.

- (Recommended) Register the worker at the root level ("/") of your application.
- Set the "Service-Worker-Allowed" response header to allow out-of-scope workers.\
`);
    }
}

const createStartHandler = (context) => {
    return function start(options, customOptions) {
        const startWorkerInstance = () => __awaiter(this, void 0, void 0, function* () {
            // Remove all previously existing event listeners.
            // This way none of the listeners persists between Fast refresh
            // of the application's code.
            context.events.removeAllListeners();
            // Handle requests signaled by the worker.
            context.workerChannel.on('REQUEST', createRequestListener(context, options));
            context.workerChannel.on('RESPONSE', createResponseListener(context));
            const instance = yield getWorkerInstance(options.serviceWorker.url, options.serviceWorker.options, options.findWorker);
            const [worker, registration] = instance;
            if (!worker) {
                const missingWorkerMessage = (customOptions === null || customOptions === void 0 ? void 0 : customOptions.findWorker)
                    ? devUtils.formatMessage(`Failed to locate the Service Worker registration using a custom "findWorker" predicate.

Please ensure that the custom predicate properly locates the Service Worker registration at "%s".
More details: https://mswjs.io/docs/api/setup-worker/start#findworker
`, options.serviceWorker.url)
                    : devUtils.formatMessage(`Failed to locate the Service Worker registration.

This most likely means that the worker script URL "%s" cannot resolve against the actual public hostname (%s). This may happen if your application runs behind a proxy, or has a dynamic hostname.

Please consider using a custom "serviceWorker.url" option to point to the actual worker script location, or a custom "findWorker" option to resolve the Service Worker registration manually. More details: https://mswjs.io/docs/api/setup-worker/start`, options.serviceWorker.url, location.host);
                throw new Error(missingWorkerMessage);
            }
            context.worker = worker;
            context.registration = registration;
            context.events.addListener(window, 'beforeunload', () => {
                if (worker.state !== 'redundant') {
                    // Notify the Service Worker that this client has closed.
                    // Internally, it's similar to disabling the mocking, only
                    // client close event has a handler that self-terminates
                    // the Service Worker when there are no open clients.
                    context.workerChannel.send('CLIENT_CLOSED');
                }
                // Make sure we're always clearing the interval - there are reports that not doing this can
                // cause memory leaks in headless browser environments.
                window.clearInterval(context.keepAliveInterval);
            });
            // Check if the active Service Worker is the latest published one
            const [integrityError] = yield until(() => requestIntegrityCheck(context, worker));
            if (integrityError) {
                devUtils.error(`\
Detected outdated Service Worker: ${integrityError.message}

The mocking is still enabled, but it's highly recommended that you update your Service Worker by running:

$ npx msw init <PUBLIC_DIR>

This is necessary to ensure that the Service Worker is in sync with the library to guarantee its stability.
If this message still persists after updating, please report an issue: https://github.com/open-draft/msw/issues\
      `);
            }
            context.keepAliveInterval = window.setInterval(() => context.workerChannel.send('KEEPALIVE_REQUEST'), 5000);
            // Warn the user when loading the page that lies outside
            // of the worker's scope.
            validateWorkerScope(registration, context.startOptions);
            return registration;
        });
        const workerRegistration = startWorkerInstance().then((registration) => __awaiter(this, void 0, void 0, function* () {
            const pendingInstance = registration.installing || registration.waiting;
            // Wait until the worker is activated.
            // Assume the worker is already activated if there's no pending registration
            // (i.e. when reloading the page after a successful activation).
            if (pendingInstance) {
                yield new Promise((resolve) => {
                    pendingInstance.addEventListener('statechange', () => {
                        if (pendingInstance.state === 'activated') {
                            return resolve();
                        }
                    });
                });
            }
            // Print the activation message only after the worker has been activated.
            yield enableMocking(context, options).catch((error) => {
                throw new Error(`Failed to enable mocking: ${error === null || error === void 0 ? void 0 : error.message}`);
            });
            return registration;
        }));
        // Defer any network requests until the Service Worker instance is ready.
        // This prevents a race condition between the Service Worker registration
        // and application's runtime requests (i.e. requests on mount).
        if (options.waitUntilReady) {
            deferNetworkRequestsUntil(workerRegistration);
        }
        return workerRegistration;
    };
};

function printStopMessage(args = {}) {
    if (args.quiet) {
        return;
    }
    console.log(`%c${devUtils.formatMessage('Mocking disabled.')}`, 'color:orangered;font-weight:bold;');
}

const createStop = (context) => {
    return function stop() {
        var _a;
        /**
         * Signal the Service Worker to disable mocking for this client.
         * Use this an an explicit way to stop the mocking, while preserving
         * the worker-client relation. Does not affect the worker's lifecycle.
         */
        context.workerChannel.send('MOCK_DEACTIVATE');
        window.clearInterval(context.keepAliveInterval);
        printStopMessage({ quiet: (_a = context.startOptions) === null || _a === void 0 ? void 0 : _a.quiet });
    };
};

function use(currentHandlers, ...handlers) {
    currentHandlers.unshift(...handlers);
}
function restoreHandlers(handlers) {
    handlers.forEach((handler) => {
        handler.markAsSkipped(false);
    });
}
function resetHandlers(initialHandlers, ...nextHandlers) {
    return nextHandlers.length > 0 ? [...nextHandlers] : [...initialHandlers];
}

const DEFAULT_START_OPTIONS = {
    serviceWorker: {
        url: '/mockServiceWorker.js',
        options: null,
    },
    quiet: false,
    waitUntilReady: true,
    onUnhandledRequest: 'warn',
    findWorker(scriptURL, mockServiceWorkerUrl) {
        return scriptURL === mockServiceWorkerUrl;
    },
};
/**
 * Returns resolved worker start options, merging the default options
 * with the given custom options.
 */
function resolveStartOptions(initialOptions) {
    return mergeRight(DEFAULT_START_OPTIONS, initialOptions || {});
}
function prepareStartHandler(handler, context) {
    return (initialOptions) => {
        context.startOptions = resolveStartOptions(initialOptions);
        return handler(context.startOptions, initialOptions || {});
    };
}

/**
 * Converts a given isomorphic request to a `MockedRequest` instance.
 */
function parseIsomorphicRequest(request) {
    const mockedRequest = {
        id: request.id,
        url: request.url,
        method: request.method,
        body: parseBody(request.body, request.headers),
        credentials: request.credentials || 'same-origin',
        headers: request.headers,
        cookies: {},
        redirect: 'manual',
        referrer: '',
        keepalive: false,
        cache: 'default',
        mode: 'cors',
        referrerPolicy: 'no-referrer',
        integrity: '',
        destination: 'document',
        bodyUsed: false,
    };
    // Attach all the cookies from the virtual cookie store.
    setRequestCookies(mockedRequest);
    return mockedRequest;
}

function createFallbackRequestListener(context, options) {
    const interceptor = createInterceptor({
        modules: [interceptFetch, interceptXMLHttpRequest],
        resolver(request) {
            return __awaiter(this, void 0, void 0, function* () {
                const mockedRequest = parseIsomorphicRequest(request);
                return handleRequest(mockedRequest, context.requestHandlers, options, context.emitter, {
                    transformResponse(response) {
                        return {
                            status: response.status,
                            statusText: response.statusText,
                            headers: response.headers.all(),
                            body: response.body,
                        };
                    },
                    onMockedResponseSent(response, { handler, publicRequest, parsedRequest }) {
                        if (!options.quiet) {
                            handler.log(publicRequest, response, handler, parsedRequest);
                        }
                    },
                });
            });
        },
    });
    interceptor.apply();
    return interceptor;
}

function createFallbackStart(context) {
    return function start(options) {
        return __awaiter(this, void 0, void 0, function* () {
            context.fallbackInterceptor = createFallbackRequestListener(context, options);
            printStartMessage({
                message: 'Mocking enabled (fallback mode).',
                quiet: options.quiet,
            });
            return undefined;
        });
    };
}

function createFallbackStop(context) {
    return function stop() {
        var _a, _b;
        (_a = context.fallbackInterceptor) === null || _a === void 0 ? void 0 : _a.restore();
        printStopMessage({ quiet: (_b = context.startOptions) === null || _b === void 0 ? void 0 : _b.quiet });
    };
}

/**
 * Pipes all emitted events from one emitter to another.
 */
function pipeEvents(source, destination) {
    const rawEmit = source.emit;
    // @ts-ignore
    if (rawEmit._isPiped) {
        return;
    }
    source.emit = function (event, ...data) {
        destination.emit(event, ...data);
        return rawEmit.call(this, event, ...data);
    };
    // @ts-ignore
    source.emit._isPiped = true;
}

// Declare the list of event handlers on the module's scope
// so it persists between Fash refreshes of the application's code.
let listeners = [];
/**
 * Creates a new mock Service Worker registration
 * with the given request handlers.
 * @param {RequestHandler[]} requestHandlers List of request handlers
 * @see {@link https://mswjs.io/docs/api/setup-worker `setupWorker`}
 */
function setupWorker(...requestHandlers) {
    requestHandlers.forEach((handler) => {
        if (Array.isArray(handler))
            throw new Error(devUtils.formatMessage('Failed to call "setupWorker" given an Array of request handlers (setupWorker([a, b])), expected to receive each handler individually: setupWorker(a, b).'));
    });
    // Error when attempting to run this function in a Node.js environment.
    if (lib$3.exports.isNodeProcess()) {
        throw new Error(devUtils.formatMessage('Failed to execute `setupWorker` in a non-browser environment. Consider using `setupServer` for Node.js environment instead.'));
    }
    const emitter = new lib$1.StrictEventEmitter();
    const publicEmitter = new lib$1.StrictEventEmitter();
    pipeEvents(emitter, publicEmitter);
    const context = {
        startOptions: undefined,
        worker: null,
        registration: null,
        requestHandlers: [...requestHandlers],
        emitter,
        workerChannel: {
            on(eventType, callback) {
                context.events.addListener(navigator.serviceWorker, 'message', (event) => {
                    // Avoid messages broadcasted from unrelated workers.
                    if (event.source !== context.worker) {
                        return;
                    }
                    const message = jsonParse(event.data);
                    if (!message) {
                        return;
                    }
                    if (message.type === eventType) {
                        callback(event, message);
                    }
                });
            },
            send(type) {
                var _a;
                (_a = context.worker) === null || _a === void 0 ? void 0 : _a.postMessage(type);
            },
        },
        events: {
            addListener(target, eventType, callback) {
                target.addEventListener(eventType, callback);
                listeners.push({ eventType, target, callback });
                return () => {
                    target.removeEventListener(eventType, callback);
                };
            },
            removeAllListeners() {
                for (const { target, eventType, callback } of listeners) {
                    target.removeEventListener(eventType, callback);
                }
                listeners = [];
            },
            once(eventType) {
                const bindings = [];
                return new Promise((resolve, reject) => {
                    const handleIncomingMessage = (event) => {
                        try {
                            const message = JSON.parse(event.data);
                            if (message.type === eventType) {
                                resolve(message);
                            }
                        }
                        catch (error) {
                            reject(error);
                        }
                    };
                    bindings.push(context.events.addListener(navigator.serviceWorker, 'message', handleIncomingMessage), context.events.addListener(navigator.serviceWorker, 'messageerror', reject));
                }).finally(() => {
                    bindings.forEach((unbind) => unbind());
                });
            },
        },
        useFallbackMode: !('serviceWorker' in navigator) || location.protocol === 'file:',
    };
    const startHandler = context.useFallbackMode
        ? createFallbackStart(context)
        : createStartHandler(context);
    const stopHandler = context.useFallbackMode
        ? createFallbackStop(context)
        : createStop(context);
    return {
        start: prepareStartHandler(startHandler, context),
        stop() {
            context.events.removeAllListeners();
            context.emitter.removeAllListeners();
            publicEmitter.removeAllListeners();
            stopHandler();
        },
        use(...handlers) {
            use(context.requestHandlers, ...handlers);
        },
        restoreHandlers() {
            restoreHandlers(context.requestHandlers);
        },
        resetHandlers(...nextHandlers) {
            context.requestHandlers = resetHandlers(requestHandlers, ...nextHandlers);
        },
        printHandlers() {
            context.requestHandlers.forEach((handler) => {
                const { header, callFrame } = handler.info;
                const pragma = handler.info.hasOwnProperty('operationType')
                    ? '[graphql]'
                    : '[rest]';
                console.groupCollapsed(`${pragma} ${header}`);
                if (callFrame) {
                    console.log(`Declaration: ${callFrame}`);
                }
                console.log('Handler:', handler);
                if (handler instanceof RestHandler) {
                    console.log('Match:', `https://mswjs.io/repl?path=${handler.info.path}`);
                }
                console.groupEnd();
            });
        },
        events: {
            on(...args) {
                return publicEmitter.on(...args);
            },
            removeListener(...args) {
                return publicEmitter.removeListener(...args);
            },
            removeAllListeners(...args) {
                return publicEmitter.removeAllListeners(...args);
            },
        },
    };
}

export { handleRequest, parseIsomorphicRequest, setupWorker };
